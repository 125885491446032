/* eslint-disable no-useless-escape */
export const checkFileExtension = (file: File, extensions: string[]) => {
    let fileExt = file.name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    return extensions.indexOf(fileExt) >= 0;
};

export const formatFileUrlToPath = (path: string): string => {
    return path.replace(/(\%2F)/g, '/').split('?alt')[0];
};

export const formatFileName = (fileName: string): string => {
    const urlRegex = /[\s\>\<\#\%\+\{\}\|\\\/\[\]\^\~\'\;\?\:\@\$\,\°\&\*]/g;
    return fileName?.replace(urlRegex, '_').toLowerCase();
};

export const validateFileType = (file: File, types: string[]): boolean => {
    return types.includes(file.type);
}

export const base64ToUnit8Array = (base64: string) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}
